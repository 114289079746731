
  import userController from '/home/builduser/work/510f9e3e59c9e626/packages/viewer-apps/antonp-viewer-app-2/src/components/MyPage/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/pureControllerWrapper.js';

  
import wrapController from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';


  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://f6291334b07e4e46a2b2617a0939ce1d@sentry-next.wixpress.com/2840',
    id: 'undefined',
    projectName: 'antonp-viewer-app-2',
    teamName: 'antonp',
    errorMonitor: true,
  };

  var experimentsConfig = {"scopes":["test"],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/510f9e3e59c9e626/packages/viewer-apps/antonp-viewer-app-2/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};

  var biConfig = null;

  var defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/510f9e3e59c9e626/packages/viewer-apps/antonp-viewer-app-2/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "Antonp Viewer App 2",
    componentName: "MyPage",
    appDefinitionId: "80d96e3d-ae80-424f-8529-41e2faa7772d",
    componentId: "f2fc225d-1998-41c9-82dc-751ba7349af3",
    projectName: "antonp-viewer-app-2",
    defaultTranslations,
    multilingualDisabled,
    shouldUseEssentials: true,
    withErrorBoundary: false,
    localeDistPath: "assets/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/510f9e3e59c9e626/packages/viewer-apps/antonp-viewer-app-2/src/components/MyPage/controller.ts",
  };

  export const controller = _controller
  export default controller;
