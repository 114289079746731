import { CreateControllerFn } from '@wix/yoshi-flow-editor';

const createController: CreateControllerFn = async ({ flowAPI }) => {
  flowAPI.panoramaClient?.transaction('My Panorama Transaction').start();

  return {
    async pageReady() {
      setTimeout(() => {
        flowAPI.panoramaClient?.transaction('My Panorama Transaction').finish();

        throw new Error('My Unhandled Controller Error');
      }, 1000);

      flowAPI.errorMonitor?.captureException(
        new Error('My Handled Controller ErrorMonitor Error'),
      );

      flowAPI.panoramaClient
        ?.errorMonitor()
        .reportError(new Error('My Handled Controller Panorama Error'));
    },
  };
};

export default createController;
